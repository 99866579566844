<template>
  <Layout tituloPagina="Ventas | Selección de cliente">
    <div class="row mb-4">
      <div class="col-md-2 col-xs-4 col-3 mb-3">
        <router-link :to="{ name: 'resumenDia' }" class="btn btn-secondary">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-clipboard-list-outline"></i>
            Resumen del día
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-clipboard-list-outline"></i>
          </span>
        </router-link>
      </div>
      <div class="col-md-8 col-xs-8 col-9">
        <filtrador
          v-on:dato-actualizado="
            dato_cliente = $event,
            refrescarClientes()
          "
          :datoini="dato_cliente"
          placeholder="Dato del cliente"
        ></filtrador>
      </div>
    </div>
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="table table-hover table-striped table-nowrap" id="tabla-cliente-venta">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Dirección</th>
                <th style="width: 150px" class="text-center">Accion</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cliente in clientes" :key="cliente.id">
                <td>{{ cliente.id }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'tableroCliente',
                      params: { id_cliente: cliente.id }
                    }"
                  >
                    {{ cliente.nombre }}
                    {{ cliente.apellido_paterno }}
                    {{ cliente.apellido_materno }}
                  </router-link>
                </td>
                <td>
                  <strong>Calle:</strong> {{ cliente.calle }} <br />
                  <strong>Colónia:</strong> {{ cliente.colonia }} <br />
                  <strong>Municipio:</strong> {{ cliente.municipio }} <br />
                  <strong>Estado:</strong> {{ cliente.estado_residencia }} <br />
                </td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'nuevaFacturaRapida',
                      params: { id_cliente: cliente.id },
                      query: { pospago: 0 }
                    }"
                    class="btn btn-success btn-sm"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                    Crear factura
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="totPag"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarClientes()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import ClienteSrv from '@/services/ClienteSrv.js'

export default {
  name: 'SeleccionClienteVenta',
  components: { Layout, Paginador, Filtrador },
  data() {
    return {
      baseUrl: window.location.origin,
      dato_cliente: '',
      clientes: [],
      total_clientes: 0,
      todos: false,
      ultima_pagina: 1,
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.seleccionClienteVenta.por_pagina') || 10),
      tc: 0,
      totPag: 0
    }
  },
  created: function() {
    var self = this

    // Carga de los querys
    if (self.$route.query.dato_cliente) self.dato_cliente = self.$route.query.dato_cliente
    if (self.$route.query.pagina) self.pagina = parseInt(self.$route.query.pagina)
    if (self.$route.query.por_pagina) self.por_pagina = parseInt(self.$route.query.por_pagina)

    if (self.dato_cliente != null && self.dato_cliente != '') {
      self.refrescarClientes()
    }
  },
  methods: {
    actualizarAPagina: function(nPag) {
      this.$router.replace({ query: { pagina: nPag } })
    },
    refrescarClientes: function(config) {
      var self = this

      iu.spinner.mostrar('#tabla-cliente-venta')

      self.$router.replace({
        query: {
          dato_cliente: self.dato_cliente,
          pagina: self.pagina,
          por_pagina: self.por_pagina
        }
      }).catch(error => {})

      if (self.dato_cliente == '') {
        self.clientes = []
        self.totPag = 0
        iu.spinner.ocultar('#tabla-cliente-venta')
        return
      }

      config = config || {}
      if (config.pagina == undefined) config.pagina = self.pagina
      if (config.por_pagina == undefined) config.por_pagina = self.por_pagina
      if (config.dato_cliente == undefined && self.dato_cliente != '') config.dato_cliente = self.dato_cliente
      if (config.activos == undefined && self.listado == 'activos') config.activos = true

      config.cfg_cliente = {
        id: true,
        id_cliente: true,
        nombre: true,
        apellido_paterno: true,
        apellido_materno: true,
        calle: true,
        colonia: true,
        municipio: true,
        estado_residencia: true
      }

      ClienteSrv.clientesJSON(config).then(response => {
        localStorage.setItem('argusblack.seleccionClienteVenta.por_pagina', self.por_pagina)
        let res = response.data
        self.clientes = res.clientes
        self.tc = res.total
        self.totPag = res.ultima_pagina

        if (self.totPag < self.pagina) {
          self.pagina = self.totPag
          self.refrescarClientes()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pidieron cargar los clientes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-cliente-venta')
      })
    },
    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.actualizarAPagina(n)
      self.refrescarClientes({ pagina: n })
    }
  }
}
</script>